
export default {
    props: {
        drug: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: true
        },
        planDrugDeductible: {
            type: [Boolean, Number],
            default: null
        },
    },
    computed: {
        drugInfo () {
            return this?.drug?.drug_info || this?.drug
        },
        drugText () {
            return this.drug?.text || this?.drug?.package?.drug?.text
        },
    },
    methods: {
        foo () {
            this.$emit('foo')
        },
        bar () {
            this.$emit('bar')
        },
        fooBar () {
            this.$emit('fooBar')
        },
        async excludeDrug () {
            const done = await this.$store.dispatch('excludeDrug', this.drug)
            if (done) {
                this.$emit('drugUpdated')
            }
        },
        async includeDrug () {
            const done = await this.$store.dispatch('includeDrug', this.drug)
            if (done) {
                this.$emit('drugUpdated')
            }
        },
    },
}
