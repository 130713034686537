
import _ from 'lodash'

export default {
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Search & Select Agent',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        internalSearch: {
            type: Boolean,
            default: false,
        },
        showNoOptions: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        helpText: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            selectableOptions: [],
            agentValue: null,
            inactive: null,
        }
    },
    async fetch () {
        const query = this.query || ''
        const { items } = await this.$api.searchAgents({ query, inactive: this.inactive })
        this.selectableOptions = items || []
    },
    watch: {
        value () {
            if (!_.isEqual(this.value, this.agentValue?.crm_id)) {
                this.updateValue()
            }
        },
        agentValue (cv1, cv2) {
            this.emitInput()
        },
    },
    mounted () {
        this.updateValue()
    },
    methods: {
        async updateValue () {
            if (this.value) {
                const data = await this.$api.getAgent(this.value)
                this.agentValue = data
            } else {
                this.agentValue = null
            }
        },
        emitInput () {
            this.$emit('input', this.agentValue?.crm_id || null)
            this.$emit('input-raw', this.agentValue || null)
        },
        search (query) {
            this.query = query
            return this.$fetch()
        },
    },
}
