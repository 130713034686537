import { render, staticRenderFns } from "./stretch.vue?vue&type=template&id=2049cbf4&lang=pug&"
import script from "./stretch.vue?vue&type=script&lang=js&"
export * from "./stretch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/codebuild/output/src2124328916/src/frontend/components/Header.vue').default,Body: require('/codebuild/output/src2124328916/src/frontend/components/Body.vue').default})
