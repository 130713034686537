
import _ from 'lodash'
import { Model as SurveyModel, ComputedUpdater } from 'survey-core'
import surveyjsJson from '~/data/survey-schedule.js'
import '~/assets/scss/survey-overrides.scss'
import { getNewClientForm } from '~/components/ClientForm.vue'

export default {
    props: {
        test: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            default: () => null
        },
        jumpToLastPage: {
            type: Boolean,
            default: false
        }
    },
    data () {
        const testForm1 = getNewClientForm(this.test)
        const testForm2 = this.test
            ? {
                returning: false,
                aep: null,
                first_name: 'Test11',
                last_name: 'Test11',
                dob: '1950-01-01',
                email: 'aziz+test@doctorschoiceusa.com',
                phone: '1234567890',
                gender: 'F',
                adding_attendees: true,
                zip_code: '01844',
                fips: '25009',
                effective_date: '2023-05-05',
                hsa_account: true,
                hsa: true,
                source_type: 'FRIEND',
                source_name: 'A1111',
                source_client: null,
                sms_opt_in: true,
                collecting_ssi: false,
                attendees: [
                    {
                        first_name: 'Test22',
                        last_name: 'Test22',
                        dob: '1950-01-01',
                        email: 'aziz+test2@doctorschoiceusa.com',
                        phone: '12345678900',
                        sms_opt_in: true,
                        gender: 'F',
                        relationship: 'SPOUSE',
                        zip_code: null,
                        fips: null,
                        caretaker: false,
                        returning: false,
                        hsa_account: false,
                        hsa: false,
                        collecting_ssi: false,
                        multiple_states: false,
                        moving: false,
                        // source_type: 'FRIEND',
                        // source_name: 'A22222',
                    },
                ],
            }
            : {}

        const form = {
            // method: 'phone',
            sms_opt_in: true,
            hsa_account: false,
            hsa: false,
            collecting_ssi: false,
            multiple_states: false,
            moving: false,
            moving_state: null,
            adding_attendees: false,
            zip_code: null,
            fips: null,
            clients: [],
            attendees: [
                {
                    sms_opt_in: true,
                    hsa_account: false,
                    hsa: false,
                    collecting_ssi: false,
                    multiple_states: false,
                    moving: false,
                    moving_state: false,
                },
            ],
            ...testForm1,
            ...testForm2,
        }

        return {
            form,
            surveyModel: null,
            goingTo: null,
            hint: null
        }
    },
    computed: {
        percentage () {
            return this.surveyModel?.progressValue
        },
        progress () {
            return Math.max(0, this.surveyModel?.currentPageNo)
        },
        total () {
            return this.surveyModel?.visiblePageCount
        },
        previousPage () {
            return this.visiblePages[this.progress - 1] || null
        },
        currentPage () {
            return this.surveyModel?.currentPage
        },
        currentPageIndex () {
            return this.progress
        },
        nextPage () {
            return this.visiblePages[this.progress + 1] || null
        },
        visiblePages () {
            return this.surveyModel?.visiblePages
        },
    },
    watch: {
        client () {
            this.postSurveyFetch()
        },
    },
    mounted () {
        this.setGlobal('$scheduleStepByStep', this)
        this.postSurveyFetch()
    },
    methods: {
        postSurveyFetch (surveyData = {}) {
            this.setBusy()
            surveyData = { ...surveyData, ...this.form, ...(this.client ? { returning: !!this.client?.id, ...this.client } : {}) }
            this.surveyModel = new SurveyModel(surveyjsJson)
            // this.surveyModel.hideRequiredErrors = true
            this.surveyModel.css = {
                question: {
                    content: 'sd-question__content question_content_custom',
                }
            }
            this.surveyModel.onCurrentPageChanging.add(this.onPageChanging)
            this.surveyModel.onCurrentPageChanged.add(this.onPageChanged)
            this.surveyModel.onComplete.add(this.onComplete)
            this.surveyModel.onValueChanged.add(this.onValueChanged)
            this.surveyModel.onUpdatePageCssClasses.add(this.onUpdatePageCssClasses)
            this.surveyModel.onAfterRenderQuestion.add(this.onAfterRenderQuestion)
            this.$watch('$route.query.progress', function (newProgress, oldProgress) {
                newProgress = parseInt(newProgress || 0) || 0
                const currentPageNo = this.surveyModel?.currentPageNo || 0
                if (this.surveyModel && currentPageNo !== newProgress) {
                    this.surveyModel.currentPageNo = newProgress
                }
            })
            // triggers the triggers & set the picked values
            _.each(surveyData, (v, k) => {
                const pick = this.surveyModel.getQuestionByName(k)?.propertyHash?.pick
                if (pick) {
                    const h = {}
                    const pickList = pick.split(',')
                    _.each(pickList, (pk) => {
                        this.surveyModel.setValue(pk, surveyData[pk])
                        h[pk] = surveyData[pk]
                    })
                    this.surveyModel.setValue(k, h)
                } else {
                    this.surveyModel.setValue(k, v)
                }
            })

            const p = this.client?.id ? this.surveyModel.getPageByName('toAddAttendeesPage') : null
            this.surveyModel.currentPageNo = parseInt(this.$route.query.progress || 0) || p?.visibleIndex || 0

            this.surveyModel.addNavigationItem({
                id: 'sv-nav-back',
                // To set the button text, use the `title` property  if you don't use localization:
                title: 'Back',
                // ... or the `locTitleName` property if you do use localization:
                // locTitleName: "clearPage",
                action: () => {
                    history.back()
                },
                css: 'nav-button',
                innerCss: 'btn sv_nav_btn sv_back_btn',
                visible: new ComputedUpdater(() => {
                    console.log('ComputedUpdater', this.currentPageIndex)
                    return this.surveyModel.isFirstPage
                }),
                visibleIndex: 0,
            })

            this.$nextTick(() => {
                this.setIdle()
                if (this.jumpToLastPage) {
                    this.visitLastPage()
                }
            })
        },
        onUpdatePageCssClasses (surveyModel, options) {
            options.cssClasses.page.root += `survey-page-name-${options.page.propertyHash.name || 'untitled'}`
        },
        onAfterRenderQuestion (surveyModel, options) {
            if (options.question?.propertyHash?.addClasses) {
                const c = [].concat(options.question?.propertyHash?.addClasses)
                for (const cls of c) {
                    options.htmlElement.classList.add(cls)
                }
            }
            if (options.question?.isRequired) {
                const element = options.htmlElement.querySelector('.sv-vue-title-additional-div')
                if (!element) {
                    return
                }
                const span = document.createElement('span')
                span.textContent = ' *'
                span.style.color = '#ff661f'
                span.className = 'required'
                element.appendChild(span)
            }

            if (options.question?.tooltip) {
                const el = `<i class="mx-1 hand"><span class="d-none bg-dark rounded text-light m-3 px-1 position-absolute">${options.question.tooltip}</span><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" class=""></path></svg></i>                `
                options.htmlElement.querySelector('.sv_qbln').insertAdjacentHTML('beforeend', el)
            }
        },
        getFirstPageWithErrors (survey) {
            for (let index = 0; index < this.goingTo; index++) {
                const canPass = survey.visiblePages[index].validate()
                if (!canPass) {
                    return index
                }
            }
            return null
        },
        onPageChanging (surveyModel, context) {
            const { oldCurrentPage, isGoingBackward } = context
            if (oldCurrentPage?.questionsValue) {
                this.updateForm(surveyModel, oldCurrentPage?.questionsValue)
            }
            this.surveyModel.lastDirection = isGoingBackward ? -1 : 1
            this.sentryBreadCrumb(`onPageChanging(isGoingBackward=${isGoingBackward})`, this.sentryBreadCrumbOptions())

            if (!isGoingBackward) {
                const firstPageWithErrors = this.getFirstPageWithErrors(surveyModel)
                if (!_.isNil(firstPageWithErrors)) {
                    context.allowChanging = false
                    this.visit(firstPageWithErrors)
                } else {
                    context.allowChanging = true
                }
            }
            this.hint = null
        },
        sentryBreadCrumbOptions (merge = {}) {
            return {
                category: 'survey',
                level: 'info',
                data: {
                    progress: this.progress,
                    total: this.total,
                    currentPage: this.currentPage?.name,
                    previousPage: this.previousPage?.name,
                    nextPage: this.nextPage?.name,
                    form: this.form,
                    ...merge,
                },
            }
        },
        onPageChanged () {
            // const message = `onPageChanged(fn=${this.form.first_name || ''},ln=${this.form.last_name || ''},p=${this.currentPage?.name || ''})`
            // this.sentryCaptureMessage(
            //     message,
            //     {
            //         contexts: {
            //             progress: this.progress,
            //             total: this.total,
            //             currentPage: this.currentPage?.name,
            //             previousPage: this.previousPage?.name,
            //             nextPage: this.nextPage?.name,
            //             ...this.form,
            //         },
            //         tags: {
            //             survey: message
            //         },
            //         screenshot: true
            //     }
            // )
            if (this.test) {
                this.$routerx.pushQuery({ progress: this.progress })
            }
            this.manageHint()
        },
        onValueChanged (surveyModel, q) {
            this.sentryBreadCrumb(`onValueChanged(${q.name}=${q.value})`, this.sentryBreadCrumbOptions())
            this.updateForm(surveyModel, surveyModel.getAllQuestions())
            this.manageHint()
        },
        manageHint () {
            const values = this.surveyModel.data
            if (this.currentPage.name === 'initial_page') {
                const hintList = []
                if (values?.returning !== undefined) {
                    if (values.returning) {
                        hintList.push(this.currentPage.hint?.returning)
                    } else {
                        hintList.push(this.currentPage.hint?.nonReturning)
                    }
                }

                if (values.adding_attendees) {
                    values.attendees.forEach((val) => {
                        if (val?.returning !== undefined && val?.caretaker) {
                            if (!val.returning) {
                                hintList.push(this.currentPage.hint.nonReturningAttendee(val.first_name))
                            } else {
                                hintList.push(this.currentPage.hint.returningAttendee(val.first_name))
                            }
                        }
                    })
                }
                this.hint = { ...this.currentPage.hint, message: hintList.join('\n') }
            }

            if (this.currentPage.name === 'contact_method') {
                let message = this.currentPage.hint?.[values?.method]
                if (values?.method === 'email' && this.surveyModel.data.aep && this.currentPage.hint?.email_in_aep) {
                    message = this.currentPage.hint?.email_in_aep
                }
                this.hint = { ...this.currentPage.hint, message }
            }

            if (!this.currentPage.hint) {
                this.hint = null
            }
        },
        onComplete () {
            return this.onSurveyDone()
        },
        updateForm (surveyModel, questions) {
            return this.updateFormFromSurveyModel(surveyModel, questions)
        },
        onSurveyDone () {},
        updateFormFromSurveyModel (surveyModel, questions) {
            questions.forEach((q) => {
                const name = q?.propertyHash?.name
                const question = surveyModel.getQuestionByName(name)

                // let value = surveyModel.valuesHash[name]
                let value = _.cloneDeep(question.value)

                // this is dump, surveyjs treats empty string as undefined, so if your API doesn't process them, you can't clear a value
                // https://github.com/surveyjs/survey-library/blob/b9657e9d2683e4aa600600c053b42dfcc9326c84/src/survey.ts#L5622-L5623
                if (value === undefined && question.getType() === 'text') {
                    value = ''
                }
                const pick = question?.propertyHash?.pick ? question?.propertyHash?.pick.split(',') : []
                if (pick?.length && value && _.isObject(value)) {
                    _.forEach(pick, (k) => {
                        if (this.form[k] !== value[k]) {
                            // console.log('$set1', k, value[k])
                            this.$set(this.form, k, value[k])
                        }
                    })
                } else if (!_.isEqual(this.form[name], value)) {
                    this.$set(this.form, name, value)
                }
            })
        },
        visit (index) {
            this.goingTo = index
            this.surveyModel.currentPageNo = index
        },
        visitLastPage () {
            this.surveyModel.currentPageNo = this.total - 1
        },
    }
}
