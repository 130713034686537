
import _ from 'lodash'

export default {
    props: {
        plan: {
            type: Object,
            default: null
        },
        drugsInfo: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            columns: [
                { key: 'tier' },
                { key: 'on_formulary' },
                { key: 'prior_auth' },
                { key: 'quantity_limit' },
                { key: 'quantity_limit_amount' },
                { key: 'quantity_limit_days' },
                { text: 'Client Usage', key: 'package.drug.quantity', getVariant: this.getVariantFromClientDailyAverage },
                { key: 'step_therapy' },
            ],
        }
    },
    methods: {
        getCell (ndc, column) {
            if (column.divide) {
                const [a, b] = column.divide
                const aVal = _.get(this.drugsInfo, `${ndc}.${a}`)
                const bVal = _.get(this.drugsInfo, `${ndc}.${b}`)
                if (bVal) {
                    return aVal / bVal
                }
                return null
            }
            return _.get(this.drugsInfo, `${ndc}.${column.key}`)
        },
        getVariantFromClientDailyAverage (ndc) {
            const dailyAverage = _.get(this.drugsInfo, `${ndc}.package.drug.daily_average`)
            const quantityLimitAmount = _.get(this.drugsInfo, `${ndc}.quantity_limit_amount`)
            const quantityLimitDays = _.get(this.drugsInfo, `${ndc}.quantity_limit_days`)
            if (quantityLimitDays) {
                const limitDailyAverage = quantityLimitAmount / quantityLimitDays
                if (dailyAverage > limitDailyAverage) {
                    return 'danger'
                } else {
                    return 'success'
                }
            } else {
                return 'warning'
            }
        },
    },
}
