import { render, staticRenderFns } from "./StepByStep.vue?vue&type=template&id=5dac6cfd&lang=pug&"
import script from "./StepByStep.vue?vue&type=script&lang=js&"
export * from "./StepByStep.vue?vue&type=script&lang=js&"
import style0 from "./StepByStep.vue?vue&type=style&index=0&id=5dac6cfd&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WizardIndicator: require('/codebuild/output/src2124328916/src/frontend/components/WizardIndicator.vue').default,WizardHelp: require('/codebuild/output/src2124328916/src/frontend/components/WizardHelp.vue').default})
