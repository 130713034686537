
import _ from 'lodash'
import { urlToLocation } from '~/util/string'

export default {
    props: {
        href: {
            type: String,
            default: null
        },
        paper: {
            type: Boolean,
            // todo: they want to disable the Anchor for now
            // apparently, no one likes you Anchor
            // I am sorry Anchor
            default: false
        }
    },
    computed: {
        hrefText () {
            if (!this.href) return this.href
            const slotText = _.get(this.$slots, 'default.0.text')
            const a = urlToLocation(this.href)
            const text = `${a.host}${a.pathname}${a.search}`.replace(/^www\./, '')
            if (slotText === text) {
                return null
            }
            return text
        },
    },
    methods: {
        isPrint () {
            return window.matchMedia && window.matchMedia('print')?.matches
        },
    },
}
