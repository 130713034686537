import { render, staticRenderFns } from "./Premiums.vue?vue&type=template&id=bcbd8e52&scoped=true&lang=pug&"
import script from "./Premiums.vue?vue&type=script&lang=js&"
export * from "./Premiums.vue?vue&type=script&lang=js&"
import style0 from "./Premiums.vue?vue&type=style&index=0&id=bcbd8e52&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcbd8e52",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/codebuild/output/src2124328916/src/frontend/components/Icon.vue').default})
