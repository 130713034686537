import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=31353d1c&lang=pug&"
import script from "./Editor.vue?vue&type=script&lang=js&"
export * from "./Editor.vue?vue&type=script&lang=js&"
import style0 from "./Editor.vue?vue&type=style&index=0&id=31353d1c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorMenu: require('/codebuild/output/src2124328916/src/frontend/components/EditorMenu.vue').default})
