import { render, staticRenderFns } from "./zen-email.vue?vue&type=template&id=7c5290c6&lang=pug&"
import script from "./zen-email.vue?vue&type=script&lang=js&"
export * from "./zen-email.vue?vue&type=script&lang=js&"
import style0 from "./zen-email.vue?vue&type=style&index=0&id=7c5290c6&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Body: require('/codebuild/output/src2124328916/src/frontend/components/Body.vue').default})
