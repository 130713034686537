
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },
    data () {
        return {
            agent: null
        }
    },
    async fetch () {
        this.agent = await this.$try('getClientAgent', this.id)
    },
}
